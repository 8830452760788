import { AxiosError } from 'axios'
import { NotificationTypes } from 'components/molecules/Notification/Notification'
import { showNotification } from 'components/organisms/NotificationRoot/NotificationRoot'
import i18n from 'i18n/i18n'
import { get, compact, map, isEmpty } from 'lodash'
import { ReactElement } from 'react'
import { AxiosRequestConfigWithRetries } from './ApiClient'
import { rawLogout } from 'components/contexts/AuthContext'

interface ValidationErrorDataType {
  [key: string]: string[]
}

interface RowValidationErrorType {
  row: number
  errors: ValidationErrorDataType
}
export interface ValidationError extends Error {
  errors: ValidationErrorDataType
  message: string
}

export interface CsvValidationError extends Error {
  errors: RowValidationErrorType[]
  rowsWithExistingInstitutionUsers?: number[]
}

export const showValidationErrorMessage = (errorData: unknown) => {
  const genericErrorData = errorData as object
  if ('code' in genericErrorData && genericErrorData?.code !== '422') {
    return
  }
  const typedErrorData = errorData as ValidationError
  if (typedErrorData?.message) {
    showNotification({
      type: NotificationTypes.Error,
      title: i18n.t('notification.error'),
      content: typedErrorData.message,
    })
  }
}

const handleError = async (error?: AxiosError) => {
  // TODO: might need some improvements + better handling of 403 errors
  const { response, config } = error || {}
  const typedConfig = config as AxiosRequestConfigWithRetries & {
    hideError?: boolean
  }
  if (typedConfig?.hideError) {
    throw error
  }
  const code = response?.status
  const specificErrors = get(response, 'data.errors', {})
  const genericErrorMessage = get(response, 'data.message', '')
  const mappedErrors = compact(
    map(specificErrors, (value) => get(value, '[0]', null))
  )
  let errorContent: ReactElement | string = ''
  if (typeof error === 'string') {
    errorContent = error
  } else if (genericErrorMessage) {
    if (typeof genericErrorMessage === 'string') {
      errorContent = genericErrorMessage
    } else {
      const typedGenericErrorMessage = genericErrorMessage as Record<
        string,
        string
      >
      errorContent = (
        <>
          {map(typedGenericErrorMessage, (error, index) => (
            <span key={index}>{error}</span>
          ))}
        </>
      )
    }
  } else if (!isEmpty(mappedErrors)) {
    // Normally these are field specific errors
    // Currently we are assuming that there will be some generic error message together with these
    errorContent = (
      <>
        {map(mappedErrors, (error, index) => (
          <span key={index}>{error}</span>
        ))}
      </>
    )
  } else {
    // unknown error
    errorContent = i18n.t('error.unknown_error', { code })
  }

  switch (code) {
    case 401:
      rawLogout()
      throw error
    case 422:
      throw error?.response?.data
    case 413:
      showNotification({
        type: NotificationTypes.Error,
        title: i18n.t('notification.error'),
        content: i18n.t('error.file_size_error'),
      })
      throw error
    case 501:
    case 502:
    case 503:
    case 504:
    case 505:
    case 506:
    case 507:
    case 508:
    case 510:
    case 511:
      showNotification({
        type: NotificationTypes.Error,
        title: i18n.t('notification.error'),
        content: i18n.t(`error.server_error.${code}`),
      })
      throw error
    default:
      showNotification({
        type: NotificationTypes.Error,
        title: i18n.t('notification.error'),
        content: errorContent,
      })
      throw error
  }
}

export default handleError
